import React from 'react';
import styles from './styles.module.scss';
import { SectionLabel } from '../../../../components/SectionLabel/SectionLabel';
import { services } from './constants';
import { ServiceCard } from './components/ServiceCard/ServiceCard';

const Services: React.FC = () => {
	return (
		<div className={styles.services} id='services'>
			<div className={styles.content}>
				<div className={styles.text}>
					<div className={styles.sticky}>
						<SectionLabel type='light'>наши</SectionLabel>
						<h2 className={styles.sectionTitle}>Услуги</h2>
						<p className={styles.sectionText}>
							Объединяя передовые технологии и глубокое понимание
							бизнес-процессов, мы предлагаем комплексное обслуживание, которое
							удовлетворяет высоким требованиям ведущих игроков рынка.
						</p>
					</div>
				</div>
				<div className={styles.cards}>
					{services.map(item => (
						<ServiceCard key={item.title} title={item.title} card={item} />
					))}
				</div>
			</div>
		</div>
	);
};

export { Services };
