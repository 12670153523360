import React from 'react';
import styles from './styles.module.scss';
import { SectionLabel } from '../../../../components/SectionLabel/SectionLabel';
import { VacancyCard } from './components/VacancyCard/VacancyCard';
import { ReactComponent as VacanciesImg } from '../../../../assets/images/vacancies-img.svg';
import { VACANCIES } from '../../../../constants/vacancies';

const Vacancies: React.FC = () => {
	return (
		<div className={styles.services} id='vacancies'>
			<div className={styles.content}>
				<div className={styles.sectionTitling}>
					<div className={styles.text}>
						<SectionLabel type='dark'>открытые</SectionLabel>
						<h2 className={styles.sectionTitle}>Вакансии</h2>
						<p className={styles.sectionText}>
							Присоединяясь к команде Comm-IT, вы окажетесь перед новыми
							вызовами и открытыми возможностями для профессионального роста и
							личностного развития. Работа у нас — это шанс приносить пользу,
							решать уникальные задачи и непрерывно учиться.
						</p>
						<p className={styles.sectionText}>
							Проверьте наши текущие вакансии — возможно, сейчас мы ищем именно
							тебя!
						</p>
					</div>
					<VacanciesImg />
				</div>
				<div className={styles.vacanciesList}>
					{VACANCIES.map(vacancy => (
						<VacancyCard key={vacancy.id} {...vacancy} />
					))}
				</div>
			</div>
		</div>
	);
};

export { Vacancies };
