import React from 'react';

const ArrowRight: React.FC = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='48'
		height='48'
		viewBox='0 0 48 48'
		fill='none'
	>
		<path
			d='M35.5921 24.0432L28.7677 17.3788C28.5132 17.1313 28.1708 16.9951 27.8158 17.0001C27.4609 17.0051 27.1225 17.1509 26.875 17.4055C26.6276 17.66 26.4914 18.0024 26.4964 18.3573C26.5014 18.7123 26.6472 19.0507 26.9017 19.2982L31.3802 23.67H18.6644C18.3109 23.67 17.9719 23.8105 17.7219 24.0604C17.472 24.3104 17.3315 24.6494 17.3315 25.0029C17.3315 25.3564 17.472 25.6954 17.7219 25.9454C17.9719 26.1954 18.3109 26.3358 18.6644 26.3358H31.3802L26.9017 30.7077C26.6472 30.9551 26.5014 31.2935 26.4964 31.6485C26.4914 32.0034 26.6276 32.3458 26.875 32.6004C27.1225 32.8549 27.4609 33.0007 27.8158 33.0057C28.1708 33.0107 28.5132 32.8745 28.7677 32.627L35.5921 25.9626C35.7211 25.8383 35.8237 25.6892 35.8938 25.5243C35.9639 25.3594 36 25.1821 36 25.0029C36 24.8237 35.9639 24.6464 35.8938 24.4815C35.8237 24.3166 35.7211 24.1676 35.5921 24.0432ZM14.6658 23.67H13.3329C12.9794 23.67 12.6404 23.8105 12.3904 24.0604C12.1404 24.3104 12 24.6494 12 25.0029C12 25.3564 12.1404 25.6954 12.3904 25.9454C12.6404 26.1954 12.9794 26.3358 13.3329 26.3358H14.6658C15.0193 26.3358 15.3583 26.1954 15.6083 25.9454C15.8582 25.6954 15.9987 25.3564 15.9987 25.0029C15.9987 24.6494 15.8582 24.3104 15.6083 24.0604C15.3583 23.8105 15.0193 23.67 14.6658 23.67Z'
			fill='#0F0B16'
		/>
	</svg>
);

export { ArrowRight };
