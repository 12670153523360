import React, { useState } from 'react';
import styles from './styles.module.scss';
import { ArrowRight } from '../../../../../../assets/icons/ArrowRight';
import { IVacancy } from '../../../../../../constants/vacancies';
import { VacancyModal } from '../VacancyModal/VacancyModal';

const VacancyCard: React.FC<IVacancy> = ({
	name,
	experience,
	format,
	responsibilities,
	requirements,
	conditions,
	industry,
	svgProp,
}) => {
	const [isOpen, setIsOpen] = useState(false);

	function handleToggleModal() {
		setIsOpen(prev => !prev);
	}

	return (
		<div className={styles.vacancyCard}>
			<h3 className={styles.title}>{name}</h3>
			<p className={styles.conditions}>
				{format} &middot; {industry} &middot; {experience}
			</p>
			<button className={styles.button} onClick={handleToggleModal}>
				<p className={styles.buttonText}>Подробнее</p>
				<ArrowRight />
			</button>
			<VacancyModal
				isOpen={isOpen}
				handleToggleModal={handleToggleModal}
				responsibilities={responsibilities}
				requirements={requirements}
				conditions={conditions}
				svgProp={svgProp}
				name={name}
			/>
		</div>
	);
};

export { VacancyCard };
