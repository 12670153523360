import React from 'react';
import Modal from '@mui/material/Modal';
import { CloseBtn } from '../../../../../../assets/icons/CloseBtn';
import styles from './styles.module.scss';
import { EIllustrationName } from '../../../../../../enums/illustrations';
import { Illustration } from '../../../../../../common/Illustration';
import { Link } from '@mui/material';

interface IVacancyModal {
	isOpen: boolean;
	handleToggleModal: () => void;
	responsibilities: string[];
	requirements: string[];
	conditions: string[];
	svgProp: EIllustrationName;
	name: string;
}

export const VacancyModal: React.FC<IVacancyModal> = ({
	isOpen,
	handleToggleModal,
	responsibilities,
	requirements,
	conditions,
	svgProp,
	name,
}) => {
	const renderSection = (title: string, items: string[]) => (
		<div className={styles.section}>
			<p className={styles.sectionTitle}>{title}</p>
			<ul className={styles.list}>
				{items.map((item, index) => (
					<li key={index} className={styles.listItem}>
						{item}
					</li>
				))}
			</ul>
		</div>
	);

	return (
		<div className={styles.modal}>
			<Modal
				open={isOpen}
				onClose={handleToggleModal}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<div className={styles.modalContent}>
					<div className={styles.svgContainer}>
						<Illustration name={svgProp} />
					</div>
					<button
						className={styles.closeBtnOverlay}
						onClick={handleToggleModal}
					>
						<CloseBtn />
					</button>
					<p className={styles.modalTitle}>{name}</p>
					<div className={styles.modalBody}>
						{renderSection('Обязанности:', responsibilities)}
						{renderSection('Требования:', requirements)}
						{renderSection('Условия:', conditions)}
						<Link
							href='mailto:info@commit.tech'
							target='_blank'
							className={styles.contactButton}
						>
							<span className={styles.btnText}>Хочу к вам!</span>
						</Link>
					</div>
				</div>
			</Modal>
		</div>
	);
};
