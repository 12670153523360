import { EIllustrationName } from '../enums/illustrations';

export interface IVacancy {
	id: number;
	name: string;
	format: string;
	industry: string;
	experience: string;
	responsibilities: string[];
	requirements: string[];
	conditions: string[];
	svgProp: EIllustrationName;
}

export const VACANCIES: IVacancy[] = [
	{
		id: 1,
		name: 'Frontend-разработчик',
		format: 'Удаленно',
		industry: 'Разработка',
		experience: 'Опыт от 4 лет',
		responsibilities: [
			'Написание и поддержка кодовой базы;',
			'Оценка трудоёмкости задач;',
			'Проведение код ревью;',
			'Взаимодействие с другими командами: аналитиками, тестировщиками и т.д.',
		],
		requirements: [
			'Уверенные знания JavaScript, TypeScript, React, Redux',
			'HTML5/CSS3, Git;',
			'Опыт работы с Zustand или другими стэйт-менеджерами;',
			'Опыт работы с Webpack;',
			'Опыт работы с NextJS;',
			'Опыт работы с Docker;',
			'Опыт написания unit-тестов.',
		],
		conditions: [
			'Полностью дистанционный формат работы;',
			'Стабильный, прозрачный доход;',
			'Оформление по ТК РФ;',
			'График работы 5/2;',
			'Работа в молодой, динамично развивающейся компании;',
			'Перспективы карьерного роста.',
		],
		svgProp: EIllustrationName.FE,
	},
	{
		id: 2,
		name: 'QA Engineer',
		format: 'Удаленно',
		industry: 'Тестирование',
		experience: 'Опыт от 2 лет',
		responsibilities: [
			'Проведение функционального тестирования. Анализ и тестирование требований;',
			'Работа в продуктовой команде, в том числе работа с ошибками;',
			'Разработка тестовой документации для проекта, подготовка тестовых данных;',
			'Сопровождение релизов;',
			'Участие в проектировании и проработке интеграций.',
		],
		requirements: [
			'Знание теории и методологии тестирования ПО;',
			'Знание артефактов тестирования (тест план, тестовый набор, тест кейс, чек-лист, баг репорт, тестовый прогон) и опыт работы с ними;',
			'Знание основ SQL (CRUD + joins);',
			'Опыт тестирования REST API (Postman), опыт работы со Swagger, положительным будет опыт работы с SOAP;',
			'Работа с БД, логами;',
			'Опыт работы с Kafka;',
			'Опыт самостоятельного тестирования на всем цикле разработки ПО.',
		],
		conditions: [
			'Полностью дистанционный формат работы;',
			'Стабильный, прозрачный доход;',
			'Оформление по ТК РФ;',
			'График работы 5/2;',
			'Работа в молодой, динамично развивающейся компании;',
			'Перспективы карьерного роста.',
		],
		svgProp: EIllustrationName.QA,
	},
	{
		id: 3,
		name: 'Системный аналитик',
		format: 'Удаленно',
		industry: 'Аналитика',
		experience: 'Опыт от 2 лет',
		responsibilities: [
			'Сбор и анализ требований;',
			'Разработка технической документации;',
			'Взаимодействие с разработчиками и тестировщиками;',
			'Участие в проектировании и проработке интеграций.',
		],
		requirements: [
			'Знание методологий анализа и проектирования;',
			'Опыт работы с UML;',
			'Опыт работы с базами данных;',
			'Опыт работы с JIRA, Confluence.',
		],
		conditions: [
			'Полностью дистанционный формат работы;',
			'Стабильный, прозрачный доход;',
			'Оформление по ТК РФ;',
			'График работы 5/2;',
			'Работа в молодой, динамично развивающейся компании;',
			'Перспективы карьерного роста.',
		],
		svgProp: EIllustrationName.SA,
	},
	{
		id: 4,
		name: 'Backend-разработчик',
		format: 'Удаленно',
		industry: 'Разработка',
		experience: 'Опыт от 4 лет',
		responsibilities: [
			'Разработка и поддержка серверной части приложения;',
			'Оценка трудоёмкости задач;',
			'Проведение код ревью;',
			'Взаимодействие с другими командами: аналитиками, тестировщиками и т.д.',
		],
		requirements: [
			'Уверенные знания Node.js, Express;',
			'Опыт работы с базами данных (MongoDB, PostgreSQL);',
			'Опыт работы с Docker;',
			'Опыт написания unit-тестов;',
		],
		conditions: [
			'Полностью дистанционный формат работы;',
			'Стабильный, прозрачный доход;',
			'Оформление по ТК РФ;',
			'График работы 5/2;',
			'Работа в молодой, динамично развивающейся компании;',
			'Перспективы карьерного роста.',
		],
		svgProp: EIllustrationName.BE,
	},
];
