import React from 'react';
import styles from './styles.module.scss';
import { SectionLabel } from '../../../../components/SectionLabel/SectionLabel';
import { ProjectCard } from './components/ProjectCard/ProjectCard';
import { projects } from './constants';

const Projects: React.FC = () => {
	return (
		<>
			<div className={styles.divider} />
			<div className={styles.container} id='projects'>
				<div className={styles.content}>
					<div>
						<SectionLabel type='light'>мы сделали</SectionLabel>
						<h2 className={styles.sectionTitle}>проекты</h2>
					</div>
					<div className={styles.subtitle}>
						Объединяя передовые технологии и глубокое понимание
						бизнес-процессов, мы предлагаем комплексное обслуживание, которое
						удовлетворяет высоким требованиям ведущих игроков рынка.
					</div>
					<div className={styles.cards}>
						{projects.map((project, index) => (
							<ProjectCard item={project} key={`${project}-${index}`} />
						))}
					</div>
				</div>
			</div>
		</>
	);
};

export { Projects };
