import React from 'react';
import styles from './styles.module.scss';
import { ArrowRight } from '../../../../assets/icons/ArrowRight';
import background from '../../../../assets/images/Ellipse.svg';
import firstScreenImg from '../../../../assets/images/first-img.svg';
import { Link } from 'react-scroll';

const FirstScreen: React.FC = () => {
	return (
		<div
			className={styles.firstScreen}
			style={{ backgroundImage: `url(${firstScreenImg}), url(${background})` }}
			id='first-screen'
		>
			<div className={styles.content}>
				<div className={styles.text}>
					<h1 className={styles.sectionTitle}>
						Цифровые
						<br />
						решения на шаг <span className={styles.highlighted}>
							впереди
						</span>{' '}
						времени
					</h1>
					<p className={styles.sectionText}>
						Comm-IT предоставляет комплексные IT-услуги для роста вашего
						бизнеса: от современной разработки ПО до оптимизации архитектуры
					</p>
					<Link
						to='contactUs'
						className={styles.button}
						activeClass={styles.active}
						smooth={true}
						duration={500}
						offset={-150}
						spy={true}
					>
						<p className={styles.buttonText}>Связаться с нами</p>
						<ArrowRight />
					</Link>
				</div>
			</div>
		</div>
	);
};

export { FirstScreen };
